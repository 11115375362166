<template>
    <section
        :id="$options.name"
        class="order-form-step preference"
    >
        <header
            class="order-form-step__heading"
            :class="{'order-form-step__heading--closed': activeStep !== $options.name }"
        >
            <h1 class="order-form-step__heading">
                <span class="order-form-step__step-number">Step 3.</span> Extras
            </h1>
            <CheckCircle
                v-if="activeStep === $options.nam"
                class="order-form-step__confirm-icon"
            />
        </header>
        <section
            v-if="activeStep === $options.name"
            class="writer-preference"
        >
            <!-- writers -->
            <div class="order-form-step__switch-row">
                <form-switch
                    :id="'chk_writers'"
                    v-model="chk_writers"
                />
                <span class="order-form-step__switch-text">
                    I want to choose a preferred writer or block certain writers from seeing my project
                </span>
            </div>
            <div class="row">
                <div
                    v-if="chk_writers"
                    class="writer-preference__input"
                >
                    <div class="order-form-step__column">
                        <form-select-writers
                            v-model="$v.preferred_writers.$model"
                            label="Preferred writers"
                            :writers="listWriters"
                            :styles="true"
                            :disabled_items="blocked_writers"
                            :errors="validationMessage($v.preferred_writers)"
                            :icon="'Preferred'"
                            :title="'Preferred writers'"
                            :writers-array-length="3"
                            name="preferred-writers"
                            @change.native="$v.preferred_writers.$touch()"
                            @blur.native="$v.preferred_writers.$touch()"
                            @loadMore="loadMoreWriters"
                            @searchWriters="onSearchWriters"
                        />
                    </div>
                    <div class="order-form-step__column">
                        <form-select-writers
                            v-model="$v.blocked_writers.$model"
                            label="Blocked writers"
                            :writers="listWriters"
                            :styles="false"
                            :disabled_items="preferred_writers"
                            :errors="validationMessage($v.blocked_writers)"
                            :icon="'Blocked'"
                            :title="'Blocked writers'"
                            :writers-array-length="3"
                            name="blocked-writers"
                            @change.native="$v.blocked_writers.$touch()"
                            @blur.native="$v.blocked_writers.$touch()"
                            @loadMore="loadMoreWriters"
                            @searchWriters="onSearchWriters"
                        />
                    </div>
                    <div class="order-form-step__switch-row">
                        <form-switch
                            :id="'chk_my_writers'"
                            v-model="chk_my_writers"
                        />
                        <span class="order-form-step__switch-text">
                            Use my preferred/blocked writers list
                        </span>
                    </div>
                </div>
            </div>
            <div class="row space-between">
                <div class="order-form-step__column order-form-step__column--chk order-form-step__column--half row">
                    <form-checkbox
                        v-model="chk_abstract"
                        class="order-form-step__checkbox"
                        :check="'abstract'"
                    />
                    <span> I need an abstract</span>
                    <span class="custom-tooltip">
                        <p>?</p>
                        <span>
                            It is a 150- to 250-word paragraph that provides readers with a quick overview of your essay or report and its organization.
                            It should express your thesis (or central idea) and your key points; it should also suggest any implications or applications of the research you discuss in the paper.
                        </span>
                    </span>
                </div>
                <div class="order-form-step__column order-form-step__column--chk order-form-step__column--half row">
                    <form-checkbox
                        v-model="chk_outline"
                        class="order-form-step__checkbox"
                        :check="'outline'"
                    />
                    <span> I need an outline</span>
                    <span class="custom-tooltip">
                        <p>?</p>
                        <span>An outline allows a writer to categorize the main points, to organize the paragraphs into an order that makes sense, and to make sure that each paragraph/idea can be fully developed.
                            Essentially, an outline helps prevent a writer from getting stuck when performing the actual writing of the essay.</span>
                    </span>
                </div>
            </div>

            <button-controls
                :settings="buttonSettings"
                :is-form-valid="!isCalculating"
                @previous="goToPrevious"
            />
        </section>
    </section>
</template>

<script>
import { eventBus } from '@/helpers/event-bus/'
// icons
import CheckCircle from 'mdi-vue/CheckCircle.vue';
import ButtonControls from '@/components/checkout/ButtonControls.vue'
// validation
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import formValidationMixin from '@/mixins/formValidationMixin.js'
import { validationRules } from '@/validation/order/RulesWriterSettings.js'
import { formMessages } from '@/validation/order/MessagesWriterSettings.js'

// vuex
import { createNamespacedHelpers, mapGetters } from 'vuex'
import { mapOrderFormFields } from '@/store/modules/order'
import {
    GET_LIST_WRITERS
} from '@/store/modules/config/action-types.js'

const { mapActions: mapConfigActions } = createNamespacedHelpers('config')

export default {
    name: 'ExtrasServices',
    components: {
        ButtonControls,
        CheckCircle
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    props: {
        isCalculating: {
            type: Boolean,
            required: true
        },
        activeStep: {
            type: String,
            required: true
        }
    },
    validations: {
        ...validationRules
    },
    data() {
        return {
            listWriters: [],
            chk_writers: false,
            chk_my_writers: false,
            isLoading: true,
            next_page_url: null,
            searchOptions: {
                page: 1,
                per_page: 10,
                mode: 'list',
                search_for: '',
                search_by: ''
            }
        }
    },
    computed: {
        ...mapOrderFormFields([
            'form_data.chk_abstract',
            'form_data.chk_outline',
            'form_data.preferred_writers',
            'form_data.blocked_writers'
        ]),
        ...mapGetters('client', [
            'getterPreferredWriters',
            'getterBlockedWriters'
        ]),
        buttonSettings() {
            return {
                previous: {
                    active: true,
                    loading: false
                },
                next: {
                    active: false,
                    loading: false
                }
            }
        }
    },
    watch: {
        chk_writers(value) {
            if (!value) {
                this.preferred_writers = []
                this.blocked_writers = []
                this.chk_my_writers = false
            }
        },
        chk_my_writers(value) {
            if (value) {
                this.preferred_writers = this.getterPreferredWriters
                this.blocked_writers = this.getterBlockedWriters
            } else {
                this.preferred_writers = []
                this.blocked_writers = []
            }
        }
    },
    async created() {
        await this.getList()
        if (this.$route.query.writer) {
            await this.getFromQuery()
        }
    },
    methods: {
        ...mapConfigActions([
            GET_LIST_WRITERS
        ]),
        validationMessage: validationMessage(formMessages),
        goToPrevious() {
            this.$emit('setPreviousStep', this.$options.name)
        },
        async getList(payload = {}) {
            try {
                this.isLoading = true
                const response = await this.getData(payload)
                this.next_page_url = response.next_page_url
                this.listWriters = [...this.listWriters, ...response.data]
                this.initCheckboxStatus()
                if (this.$route.name === 'order_edit') {
                    this.preferred_writers = this.writerAdapter(this.preferred_writers)
                    this.blocked_writers = this.writerAdapter(this.blocked_writers)
                }
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.isLoading = false
            }
        },
        async getData(payload) {
            const data = await this[GET_LIST_WRITERS]({
                ...this.searchOptions,
                ...payload
            })
            return data
        },
        writerAdapter(list) {
            return list.map((writer) => {
                if (writer.id) return writer
                const writerObject = this.listWriters.find((item) => item.id === writer)
                return writerObject
            })
        },
        initCheckboxStatus() {
            if (this.chk_writers === false) {
                if (this.preferred_writers.length > 0 || this.blocked_writers.length > 0) {
                    this.chk_writers = true
                }
            }
        },
        async loadMoreWriters(scrollChecker) {
            if (scrollChecker && (!this.isLoading && !!this.next_page_url)) {
                this.searchOptions.page += 1
                await this.getList()
            }
        },
        async onSearchWriters(payload) {
            let search_by = 'nickname'
            if (/^\d+$/.test(payload)) {
                search_by = 'sw_id'
            }
            this.searchOptions = {
                ...this.searchOptions,
                page: 1,
                search_by,
                search_for: payload
            }
            this.listWriters = []
            await this.getList()
        },
        async getFromQuery() {
            if (!this.listWriters.find((item) => +item.id === +this.$route.query.writer)) {
                await this.getList({
                    search_by: 'sw_id',
                    search_for: this.$route.query.writer
                })
            }
            this.preferred_writers = this.writerAdapter([+this.$route.query.writer])
        }
    }
}
</script>

<style lang="scss">
.writer-preference__input{
    width: 100%;
    padding-right: 80px;
    margin-top: -20px;
    .order-form-step__switch-row{
        padding-top: 0;
        padding-bottom: 0;
    }
}
</style>
